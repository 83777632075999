<template>
	<div class="card d-flex flex-column align-items-stretch justify-content-between px-xxl-15 px-xl-10 px-25">
		<div class="d-flex justify-content-center">
			<div class="image-container">
				<img :src="adminAccount.avatar ? adminAccount.avatar : '/media/svg/avatars/default-avatar.png'" class="img-fluid" alt="avatar" />
			</div>
		</div>
		<div class="text-center mt-5">
			<h4 class="card-title font-weight-bolder text-dark-50">{{ adminAccount.name }}</h4>
			<h3 class="card-title font-weight-bolder text-dark mb-3">
				<span class="username" v-b-tooltip="'View as user'" @click="viewAs()">{{ adminAccount.username }}</span>
			</h3>
			<h4 class="card-title font-weight-bolder text-primary text-uppercase">{{ adminAccount.role }}</h4>
		</div>
		<div class="d-flex justify-content-between">
			<div class="text-center">
				<div class="stat">{{ adminAccount.sites.length || 0 }}</div>
				<div><h6>SITES</h6></div>
			</div>
			<div class="text-center">
				<div class="stat">
					<span>{{ reports }}</span>
				</div>
				<div><h6>REPORTS</h6></div>
			</div>
			<div class="text-center">
				<div class="stat">{{ adminAccount.voipcount || 0 }}</div>
				<div><h6>VOIP</h6></div>
			</div>
		</div>
		<div class="mt-5">
			<h4>About</h4>
			<div class="mb-1">
				<span class="font-weight-light">Created: </span> <span class="font-weight-bold">{{ adminAccount.DateAdded }}</span>
			</div>
			<div class="mb-1">
				<div class="font-weight-light">
					Last Login @: <span class="font-weight-bold">{{ adminAccount.lastlogin }}</span>
				</div>
				<div v-if="adminAccount.lastip" class="mt-1 font-weight-light">
					Last Login From: <span class="font-weight-bold">{{ adminAccount.lastip }}</span>
				</div>
			</div>
			<div>
				<div class="font-weight-light">
					Password Changed: <span class="font-weight-bold">{{ adminAccount.password_changed }}</span>
				</div>
			</div>
		</div>
		<div class="my-5 text-center">
			<b-button v-b-modal="'myModal' + adminAccount.AccountID" variant="outline-danger"><i class="flaticon2-trash" />Delete Account</b-button>
		</div>
		<b-modal
			:id="'myModal' + adminAccount.AccountID"
			title="Delete Account"
			ok-title="Delete"
			ok-variant="danger"
			@ok="deleteAccount(adminAccount.AccountID)"
		>
			<p class="my-4">
				Are you sure you want to delete account with username: <b> {{ adminAccount.username }} </b> ({{ adminAccount.name }})?
			</p>
		</b-modal>
		<b-modal id="redirectingtoadmin" hide-footer>
			<div class="d-block text-center">
				<h3>Account Deleted!</h3>
				<h3>Redirecting to the admin page...</h3>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ENABLE_VIEWAS } from '@/core/services/store/viewas.module';

export default {
	name: 'AccountSidebar',
	data() {
		return {
			loaded: false,
		};
	},
	computed: {
		...mapGetters(['adminAccount']),
		reports() {
			if (this.adminAccount.reports && this.adminAccount.reports.length) {
				return this.adminAccount.reports.length;
			} else {
				return 0;
			}
		},
	},
	methods: {
		viewAs() {
			this.$store
				.dispatch(ENABLE_VIEWAS, {
					AccountID: this.adminAccount.AccountID,
					name: this.adminAccount.name,
					username: this.adminAccount.username,
					avatar: this.adminAccount.avatar,
				})
				.then(() => {
					this.$router.push({ name: 'dash' });
				});
		},
		deleteAccount(id) {
			this.$http
				.post('deleteaccount', { id: id })
				.then(res => {
					if (res.data.data) {
						this.$bvModal.show('redirectingtoadmin');
						setTimeout(() => {
							this.$router.push('/admin');
						}, 2000);
					}
				})
				.catch(err => {
					console.log('Delete Site Error:', err);
				});
		},
	},
};
</script>

<style scoped>
.image-container {
	width: 200px;
	padding: 10px;
	margin-top: 40px;
	background: rgb(233, 232, 232);
	border-radius: 10px;
}
.username:hover {
	cursor: pointer;
	color: #3699ff;
}
.stat {
	font-size: 2em;
	color: #3699ff;
}
</style>
